<template>
    
    <v-stepper 
        alt-labels
        non-linear
        v-model="getCurrentStep"
        class="pa-0"
        style="position: fixed; z-index: 10; width: 100%"   
    >
        <v-stepper-header>
            <template v-for="step in steps">
                
                <v-stepper-step
                    :key="`${step.number}-step`"                    
                    :complete="formProgress[step.model]"
                    :step="step.number"
                    @click="displaySection(step.route, step.number)"
                    color="primary"
                    editable
                    class="text-caption py-3"
                >
                    {{ step.label }}
                </v-stepper-step>

                <v-divider
                    v-if="step.number !== numberOfSteps"
                    :key="step.number"
                ></v-divider>                 
                

            </template>
        </v-stepper-header>
    </v-stepper>

    <!-- <v-slider
        :tick-labels="tickLabels"
        :max="steps.length"
        ticks="always"
        tick-size="10"
        thumb-label="always"
    >

    </v-slider> -->
   
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
    name: 'FormProgress',
    data: () => ({
        steps: [
            {label: 'Student Data', number: 1, model: 'studentData', route: '/registration/student-data'},
            {label: 'Family Data', number: 2, model: 'familyData', route: '/registration/family-data'},
            {label: 'Health Data', number: 3, model: 'healthData', route: '/registration/health-data'},
            {label: 'Other Data', number: 4, model: 'otherData', route: '/registration/other-data'},
            {label: 'Documents', number: 5, model: 'documents', route: '/registration/documents'},
            {label: 'Complete', number: 6, model: 'submit', route: '/registration/complete'},           
        ],          
    }),    
    computed: {
        numberOfSteps: function () {
            return this.steps.length;
        },
        ...mapGetters({
            getCurrentStep: 'form/getCurrentStep',
            formProgress: 'form/getFormProgress',
        }),

        tickLabels(){
            let labels = [];
            this.steps.forEach(step => {
                labels.push(step.label)
            })
            console.log(labels);
            return labels;
        }
    },
    methods: {
        ...mapMutations({
            setCurrentStep: 'form/setCurrentStep'
        }),
        displaySection(route, step){            
            let currentRoute = this.$route.fullPath;
            if(currentRoute != route) {
                this.setCurrentStep(step);
                this.$router.push(route); 
            }
        }
    }
}
</script>

<style scoped>
    .v-stepper--alt-labels .v-stepper__step{
        flex-basis: 0 !important;
    }

    .v-stepper--alt-labels .v-stepper__header .v-divider{
        margin: 35px -10px 0 !important;
    }
</style>