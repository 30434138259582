<template>
    <layout>
        <v-overlay            
            opacity="0.8"
            z-index="10"                    
        >            
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>                   
        </v-overlay>
    </layout>    
</template>

<script>
import Layout from './layouts/App';
import { mapActions } from 'vuex' 
export default {
    components: {
        Layout
    },

    created: function () {
        setTimeout(this.initialize, 1000);        
    },

    data: () => ({
        value: true,
    }),
    
    methods: {
        ...mapActions({
            signOut: 'auth/signOut',
        }),

        async initialize () {
            console.log("initialize logoff");
            try {
                await this.signOut();
                console.log("logout complete");
            } catch (error) {
                console.log(error);
            }             
            this.$router.replace('/')
        }
    }
}
</script>