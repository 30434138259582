<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col>
                <v-card
                    height="82vh"
                >
                    <iframe ref="pdf" style="width:100%; height:100%" frameBorder="0" :src="src"></iframe>  
                </v-card>
            </v-col>
        </v-row>
       
    </v-container>
</template>

<script>
export default {
    name: 'Reports',
    data: () => ({
        formClasses: [],
        classSelected: 'All',
        src: process.env.VUE_APP_API_URI + '/api/registration-report/all'
    })
}
</script>