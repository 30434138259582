<template>
    <v-btn        
        text
        v-on:click="logOut"
      >
        <span class="mr-2 secondary--text">Logout</span>
        <v-icon
            color="secondary"
        >mdi-logout</v-icon>
      </v-btn>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
    methods: {
        ...mapActions({
            signOut: 'auth/signOut',
        }),

        ...mapMutations({
            setOverlay: 'auth/setOverlay',         
        }),

        async logOut () {
            console.log('loging out...');            
            this.setOverlay(true);
            try {
                await this.signOut();
                console.log("logout complete");
            } catch (error) {
                console.log(error);
            }           
            
            this.setOverlay(false);
            this.$router.replace('/')
        }

        
    }
}
</script>