import Login from './components/auth/Login'
import ChangePassword from './components/auth/ChangePassword'
import Dashboard from './components/Dashboard'
import StudentData from './components/StudentData'
import FamilyData from './components/FamilyData'
import HealthData from './components/HealthData'
import OtherData from './components/OtherData'
import Documents from './components/Documents'
import Complete from './components/Complete'
import AdminLogin from './components/auth/AdminLogin'
import Admin from './components/Admin'
import TermReports from './components/TermReports'
import Logout from './components/Logout'
//import Logout from './components/auth/Logout'

const routes = [
  { 
      path: '/',
      name: 'login', 
      component: Login,
      meta: {
        requiresVisitor: true,
      }
  },
  { 
    path: '/admin',
    name: 'admin', 
    component: AdminLogin,
    meta: {
      requiresVisitor: true,
    }
  },
  { 
    path: '/admin/dashboard',
    name: 'adminDashboard', 
    component: Admin,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },   
  {
    path: '/registration/student-data',
    name: 'studentData',
    component: StudentData,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registration/family-data',
    name: 'familyData',
    component: FamilyData,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registration/health-data',
    name: 'healthData',
    component: HealthData,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registration/other-data',
    name: 'otherData',
    component: OtherData,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registration/documents',
    name: 'documents',
    component: Documents,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registration/complete',
    name: 'complete',
    component: Complete,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/registration/change-password',
    name: 'changePassword',
    component: ChangePassword,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/term-reports',
    name: 'termReports',
    component: TermReports,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '*',
  //   component: NotFoundComponent
  // }  
]

export default routes