<template>
    <v-app>
        <v-app-bar 
            app
            color="primary"
            dark
            clipped-left
            elevation="0"
        >
            <div class="d-flex align-center secondary--text text-weight-bold">
                <v-img
                alt="Vuetify Logo"
                class="shrink mr-2"
                contain
                src="../assets/logo.png"
                transition="scale-transition"
                width="40"
                />
                {{ schoolName}}
                
            </div>
            <v-spacer></v-spacer>
            <Logout/>
        </v-app-bar>

        <v-navigation-drawer
            :mini-variant.sync="mini_md"            
            app
            clipped
            color="primary"
            
            permanent
            width="200"
            class="d-block"
        >         
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="title secondary--text">
                        Student Portal
                    </v-list-item-title>
                    <v-list-item-subtitle class="secondary--text">
                        Administrator
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            
            <AdminNavigation/>
        </v-navigation-drawer>

        <!-- <v-navigation-drawer
            expand-on-hover
            app
            clipped
            :color="themeColor"
            dark
            permanent
            width="200"
            class="d-md-none"
        >         
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="title">
                        Student Portal
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        Administrator
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            
            <AdminNavigation/>
        </v-navigation-drawer> -->

        <v-main class="grey lighten-5">
            <AdminDashboard v-if="menuOptions['dashboard']"/>
            <Reports v-if="menuOptions['reports']"/>
            <PostTermReports v-if="menuOptions['postReports']"/>
            <v-dialog
                v-model="menuOptions['exportData']"
                persistent
                width="300"
            >
                <v-card
                    :color="themeColor"
                    dark
                    class="pa-4"
                >
                    <v-card-text>
                        Downloading data...
                        <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-main>
        
    </v-app>
</template>

<script>
import Logout from './auth/Logout';
import AdminNavigation from './AdminNavigation';
import AdminDashboard from './AdminDashboard';
import Reports from './Reports';
import PostTermReports from './PostTermReports';
import { mapGetters } from 'vuex';

export default {
    name: 'Admin',
    components: {
        Logout,
        AdminDashboard,
        Reports,
        AdminNavigation,
        PostTermReports,
    },
    
    computed: {      
      themeColor(){          
          return this.primaryColor + ' ' + this.colorDarken;
      },
      avatarColor(){
          return this.avatarColor + ' ' + this.avatarDarken;
      },      
        ...mapGetters({
        getUser: 'form/getUser',
        schoolName: 'form/getSchoolName',
        primaryColor: 'form/getPrimaryColor',
        colorDarken: 'form/getColorDarken',
        showFormProgress: 'form/getShowFormProgress',
        avatarColor: 'form/getAvatarColor',
        avatarDarken: 'form/getAvatarDarken',
        //dashboard: 'admin/getDashboard',
        //exportData: 'admin/getExportData',
        //reports: 'admin/getReports',
        //importStudents: 'admin/getImportStudents',
        menuOptions: 'admin/getMenuOptions',
      }),           
    },
    
    data: () => ({                
        dialog: false,
        mini_md: false,        
    }),
    methods: {
        
        
    }
}
</script>