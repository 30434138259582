<template>
    <layout>
        <v-row
            align="center"
            justify="center"
        >            
            <v-card            
                width="500"
            >  
                <v-card-title
                    :class="cardTitleClass"               
                >
                    Select Term Report
                </v-card-title>
                
                <v-card-text style="height: 50vh; overflow-y: scroll">
                    <v-list two-line>
                        <v-list-item-group
                            v-model="reportSelected"
                            active-class="primary--text"
                        
                        >
                            <template v-for="(report, index) in reports">
                                <v-list-item 
                                    :key="report.id" 
                                    @click="displayReportCard(report)"
                                    :disabled="report.disabled"
                                    :style="report.style"
                                >
                                    <template v-slot:default="{ active }">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="report.title"></v-list-item-title>

                                            <v-list-item-subtitle
                                                class="text--primary"
                                                v-text="report.headline"
                                            ></v-list-item-subtitle>                                  

                                        
                                            <v-list-item-subtitle
                                                v-text="report.subtitle"
                                            ></v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-list-item-action-text v-text="report.action"></v-list-item-action-text>
                                            <v-icon
                                                v-if="!active"
                                                color="grey lighten-1"
                                            >
                                                mdi-star-outline
                                            </v-icon>

                                            <v-icon
                                                v-else
                                                color="yellow darken-3"
                                            >
                                                mdi-star
                                            </v-icon>

                                        </v-list-item-action>                                    

                                    </template>
                                </v-list-item>

                                <v-divider
                                    v-if="index < reports.length - 1"
                                    :key="index"
                                ></v-divider>

                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
               
            </v-card>
        </v-row>
        <v-dialog
            v-model="reportCard"
            max-width="95vw"
            fullscreen          
            persistent
        >   
            <v-card
                height="93vh" 
            >
                <iframe ref="pdf" style="width:100%; height:100%" frameBorder="0" :src="src"></iframe>                
            </v-card>
            <v-container
                fluid
                class="pa-0"
            >
                <v-sheet>
                    <v-row
                        justify="end"
                        class="ma-0"
                    >
                        
                        <v-btn                                            
                            color="primary"
                            small
                            dark
                            @click="closeReportCard"                                            
                            class="my-2 mr-4"
                        >
                            Close
                        </v-btn>
                        
                    </v-row>
                </v-sheet>
            </v-container>                        
        </v-dialog>               
    </layout>    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Layout from './layouts/App';
export default {
    name: 'TermReport',
    components: {
        Layout,                  
    },       
    computed: {
        ...mapGetters({
            primaryColor: 'form/getPrimaryColor',
            colorDarken: 'form/getColorDarken',
            user: 'auth/getUser',
            admin: 'auth/getAdmin'            
        }),
        themeColor(){          
          return 'primary';
        },
        cardTitleClass(){
            return this.themeColor + ' secondary--text';
        }
    },
    mounted(){
        //console.log('term report mounted');
        //console.log(`Admin: ${ this.admin }`);
        this.availableReports();
    },    
    data: () => ({
        reportCard: false,        
        reports: [],
        reportSelected: {
            action: '',
            headline: '',
            subtitle: '',
            title: '',
            term: '',
            year: '',
        },
        src: "",       
        tab: null,
        overlay: false,
    }),
    methods: { 
        ...mapActions({
            getReportTerms: 'report/getReportTerms',
        }),
        ...mapMutations({
            setReportPeriods: 'report/setReportPeriods',
        }),       
        closeReportCard(){
            this.src = "";
            this.reportCard = false;
        },
        displayReportCard(report){
            let id = this.user.student_id;
            let year = report.year;
            let term = report.term;
            this.src = process.env.VUE_APP_API_URI + "/api/report-card/"+id+"/"+year+"/"+term;
            this.reportCard = true;
            console.log(this.src);
        },
        async availableReports(){            
            this.overlay = true;            
            try {
                let response = await this.getReportTerms();                
                this.createReportList(response.data);
                this.overlay = false;
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error);
            }
        },
        createReportList(data){
            let termId = 100;
            //console.log(data);
            data.forEach(record => {
                let posted = 'Report posted ' + ((record.date_posted == null) ? '' : record.date_posted);
                let recordPosted = true;
                let termName = '';                
                switch(record.term){
                    case '1':
                        termName = 'Michaelmas';
                        break;
                    case '2':
                        termName = 'Hilary';
                        break;
                    case '3':
                        termName = 'Trinity';
                        break;
                }
                //console.log(`Term Name: ${termName}`);
                let recordStyle = '';
                if(record.posted == 0){
                    posted = 'Report not posted';    
                    recordPosted = false;
                    //console.log(this.admin);
                    if(this.admin) recordPosted = true;
                    recordStyle = 'background: gainsboro'
                }            
                let termReport = {
                    id: ++termId,
                    action: posted,
                    title: 'Form ' + record.form_level + ' ' + record.class_id,
                    headline: 'End of Term Report',
                    subtitle: 'Term ' + record.term + ' ' + termName + ' ' + record.year + '-' + (parseInt(record.year) + 1),
                    term: record.term,
                    year: record.year,
                    disabled: !recordPosted,
                    style: recordStyle,
                }
                this.reports.push(termReport);
            })
            //console.log(this.reports);
        }        
    }

   
    
}
</script>

<style scoped>
    ::v-deep .v-dialog--fullscreen > .v-card{
        min-height: 0;
    }
</style>