import axios from 'axios'

export default{
    namespaced: true,

    state: {
        reportPeriods: []
    },

    getters: {
        getReportPeriods(state){
            return state.reportPeriods;
        }
    },

    mutations: {
        setReportPeriods(state, value){
            state.reportPeriods = value;
        }
    },

    actions: {
        getReportTerms({ rootGetters }){
            let url = '/api/report-card-terms/' + rootGetters['auth/getUserID'];              
            return axios.get(url);
        }
    },
}