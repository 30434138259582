export default{
    namespaced: true,    
    state: {       
        selectedTab: null
    },
    getters: {
        getSelectedTab(state){
            return state.selectedTab
        }
    },
    mutations: {
        setSelectedTab(state, value){
            state.selectedTab = value
        }
    }
}