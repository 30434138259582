import Vue from 'vue'
import Main from './components/layouts/Main'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import routes from './routes'
import store from './store'
import VueDateFns from 'vue-date-fns'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueDateFns)

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    //console.log("Authenticated: ", store.getters['auth/authenticated']);
    if (!store.getters['auth/getAuthenticated']) {
      next({
        path: '/',
        
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    //console.log("Authenticated: ", store.getters['auth/authenticated']);
    if (store.getters['auth/getAuthenticated']) {
      next({
        path: '/registration/student-data',
        
      })
    } else {
      next()
    }
  }else {
    next() // make sure to always call next()!
  }
})

// store.dispatch('auth/checkSession').then(() => {
//   new Vue({
//     vuetify,
//     router,
//     store,
//     render: h => h(Main)
//   }).$mount('#app')
// })

new Vue({
  vuetify,
  router,
  store,
  render: h => h(Main)
}).$mount('#app')

