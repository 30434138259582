<template>
    <v-row>
        <v-col>
            <span
                class="mx-2 font-weight-light"
            >
                Welcome
            </span>
            <span
                class="text-h6 font-italic primary--text"
            >
                {{ user.name }}
            </span>
            
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
             user: 'auth/getUser',
        })
    }
}
</script>