import axios from 'axios'

export default {
    namespaced: true,

    state: {       
        menuOptions: {
            dashboard: true,
            exportData: false,
            reports: false,
            importStudents: false,
            postReports: false,
        },
        editedTerm: {},
        editedStudent: {},        
    },

    getters: {        
        getMenuOptions(state){
            return state.menuOptions
        },
        getEditedTerm(state){
            return state.editedTerm
        },
        getEditedStudent(state){
            return state.editedStudent
        }
    },

    mutations: {        
        setMenuOptions(state, value){
            state.menuOptions = value
        },
        setEditedTerm(state, value){
            state.editedTerm = value
        },
        setEditedStudent(state, value){
            state.editedStudent = value
        }
    },

    actions: {
        downloadRegistrationData(){
            return axios({
                method: 'get',
                url: '/api/registration-data-spreadsheet',
                responseType: 'arraybuffer',
            });
        },

        resetPassword(state, id){
            let url = '/api/reset-password-student';
            return axios.post(url, {
                "student_id" : id
            })            
        },

        getReportsPosted(){
            let url = '/api/term-reports-posted';
            return axios.get(url);
        },

        postTermReports({ getters }){
            let url = 'api/post-term-reports';
            return axios.post(url, getters.getEditedTerm);
        },

        postStudent({ getters}){
            let url = 'api/student';
            return axios.post(url, getters.getEditedStudent);
        }
    }
}    