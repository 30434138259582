import axios from 'axios'

export default{
    namespaced: true,
    state: {        
        record: {},             
    },

    getters: { 
        getRecord (state) {
            return state.record
        },       
    },

    mutations: { 
        setRecord (state, value) {
            state.record = value
        },       
    },

    actions: {
        getRecord ({ rootGetters }) {
            const url = '/api/student-data-files/' + rootGetters['auth/getUser'].student_id;
            return axios.get(url);
        },
        
        postRecord ({ getters }) {
            const url = '/api/student-data-files';
            return axios.post(url, getters.getRecord);
        }
    }
}