<template>
    <layout>
        <user/>

        <v-row>
            <v-col>
                <v-card
                    v-for="(item, i) in items"
                    :key="i"
                    class="d-xs-block d-sm-none mx-auto ma-12"
                    width="180"                    
                    :to="item.route"                    
                >                   
                    <v-card-title
                        class="d-flex flex-column justify-center"
                    >
                        <v-avatar
                            color="primary"
                            dark
                            size="100"
                        >
                            <v-icon 
                                x-large 
                                color="white"
                            >
                                {{ item.icon }}
                            </v-icon>
                        </v-avatar>                        
                    </v-card-title>
                    <v-card-text
                        class="d-flex justify-center"
                    >
                        {{ item.text }}
                    </v-card-text>
                    
                </v-card>               
                
                <v-card
                    class="d-none d-sm-block elevation-4 mx-auto "
                    :width="width"
                >
                    <v-card-title
                        class="py-2 pl-8 caption secondary--text font-weight-bold text-uppercase primary"
                    >
                        <v-spacer></v-spacer>
                            <span>Student Portal</span>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-row class="pa-4">
                        <v-col class="d-flex align-center">
                            <v-list> 
                                <v-list-item
                                    v-for="(item, i) in items"
                                    :key="i"
                                    :to="item.route"
                                >                                    
                                    <v-list-item-content>
                                        <v-list-item-title 
                                            v-text="item.text"
                                            class="font-weight-bold text-uppercase primary--text"
                                        ></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>                                
                            </v-list>
                        </v-col>
                        <v-col class="d-flex justify-center">
                            <v-img
                            src="../assets/logo.png"
                            max-width="120"
                            contain
                            class="d-flex"
                            ></v-img> 
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        
        <v-overlay
            :value="overlay"
            opacity="0.8"
            z-index="10"                    
        >
            
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>                   
        </v-overlay>
    </layout>     
    
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from './layouts/App';
import User from './User';
export default { 
    components: {
        Layout,
        User,
    },      

    computed: {
        ...mapGetters({         
          user: 'auth/getUser',
          schoolName: 'form/getSchoolName',
          overlay: 'auth/getOverlay',
          student: 'auth/getStudentRecord',                        
        }),

        width () {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return "300"
                default: return "460";
            }
        }
                  
    },
       
    data: () => ({
        items: [
            {
                icon: 'mdi-file-certificate',
                text: 'View Term Reports',
                menu: 'term-reports',
                route: '/term-reports'
            },
            {
                icon: 'mdi-keyboard',
                text: 'Edit/View Registration',
                menu: 'edit-view-registration',
                route: '/registration/student-data',
            },           
        ],               
               
    }),  
    
}
</script>